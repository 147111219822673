<script>
import moment from "moment";
export default {
  data() {
    let date = moment(this.$route.params.date).format("DD.MM.YYYY");
    return {
      moment: moment,
      breadItems: [
        {
          text: "Расходы",
          disabled: false,
          href: "/#/expenditure"
        },
        {
          text: `Расходы за ${date}`,
          disabled: true,
          href: "breadcrumbs_link_2"
        }
      ],
      headers: [
        { value: "name", text: "Название", sortable: false },
        { value: "price", text: "Сумма, тг", sortable: false },
        { value: "actions", text: "Действия", sortable: false }
      ],
      loading: false,
      options: {},
      items: []
    };
  },
  watch: {
    options: {
      async handler() {
        this.items = [];
        await this.getList();
      },
      deep: true
    }
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let res = (
          await window.axios.get(`/api/v2/booking/consumption`, {
            params: {
              date: this.$route.params.date
            }
          })
        ).data;
        console.log(res);
        this.items = res.map(item => {
          return {
            name: item.expenditure,
            price: item.price,
            consumption_id: item.consumption_id,
            expenditure_id: item.expenditure_id
          };
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    openInput(item) {
      item.input_here = !item.input_here;
      this.$forceUpdate();
    },
    async saveInput(item) {
      try {
        console.log(item);
        if (item.consumption_id) {
          window.axios.patch(
            `/api/v2/booking/consumption/${item.consumption_id}/`,
            {
              price: +item.price,
              date: this.$route.params.date,
              expenditure: item.expenditure_id
            }
          );
        } else {
          window.axios.post("/api/v2/booking/consumption/", {
            price: +item.price,
            date: this.$route.params.date,
            expenditure: item.expenditure_id
          });
        }
        item.input_here = false;
        this.getList();
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
  }
};
</script>

<template>
  <v-container class="report">
    <v-card class="px-5 py-3">
      <v-breadcrumbs class="ma-0 pa-0" :items="breadItems"></v-breadcrumbs>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="name"
        hide-default-footer
        :loading="loading"
        loading-text="Идёт загрузка данных..."
        :options.sync="options"
        :server-items-length="items.length"
      >
        <template v-slot:[`item.price`]="{ item }">
          <span
            v-if="!item.input_here"
            v-text="item.price.toLocaleString('KZ-kz')"
          ></span>
          <v-text-field v-else v-model="item.price"> </v-text-field>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="!item.input_here"
            @click="openInput(item)"
            color="green"
            icon
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn v-else @click="saveInput(item)" color="primary"
            >Сохранить</v-btn
          >
        </template>
      </v-data-table>
      <div class="report_final">
        <p>Итого</p>
        <p>
          {{
            items
              .reduce((prev, cur) => {
                return prev + +cur.price;
              }, 0)
              .toLocaleString("KZ-kz")
          }}
          тг
        </p>
      </div>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped>
.report {
  h3 {
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #021011;
    @media (orientation: portrait) {
      font-size: 18px;
    }
  }
  &_final {
    margin-top: 40px;
    background: rgba(255, 165, 0, 0.2);
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #ffa500;
    padding: 13px;
    display: grid;
    grid-template-columns: repeat(2, 55%);
    p {
      margin: 0;
    }
  }
}
</style>
